import React from "react";
import { graphql } from "gatsby";

import { AirtableData, Query } from "../graphqlTypes";

import Layout from "../components/Layout";
import SEO from "../components/seo";

import { Race } from "../models";

import { RaceTable } from "../components/TableView/RaceTable";

// Take seconds and format as HH:MM:SS
function formatTime(timestamp: number) {
  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - hours * 60;
  const seconds = Math.round(timestamp % 60);

  return [hours, minutes, seconds]
    .map((n) => n.toString().padStart(2, "0"))
    .join(":");
}

function toRace(n: AirtableData): Race {
  return {
    name: n.Name || "",
    type: n.Type || "",
    distance: n.Distance || 0.0,
    time: n.Time ? formatTime(n.Time) : "",
    location: n.Location || "",
    date: n.Date,
    shoes: n.Shoes || "",
    status: n.Status || "",
    notes: n.Notes || "",
  };
}

const IndexPage = ({ data }: { readonly data: Query }) => {
  const races = data.allAirtable
    ? data.allAirtable.edges
        .map((e) => (e.node.data ? toRace(e.node.data) : null))
        .filter((x): x is Race => x !== null)
    : null;

  return (
    <Layout>
      <SEO title="Home" />
      {races && <RaceTable races={races} />}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query contentQuery {
    allAirtable(sort: { fields: data___Date, order: DESC }) {
      edges {
        node {
          data {
            Name
            Type
            Distance
            Time
            Location
            Date(formatString: "MMMM D, YYYY")
            Shoes
            Status
            Notes
          }
        }
      }
    }
  }
`;
