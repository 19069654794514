import React from "react";

import { Race } from "../../models";

const RaceCell: React.FC = ({ children }) => (
  <td className="p-2">{children}</td>
);

const RaceRow: React.FC<{ readonly race: Race }> = ({ race }) => (
  <tr>
    <RaceCell>
      {race.name}
      <div className="text-bluegray-700">{race.location}</div>
    </RaceCell>
    <RaceCell>{race.type}</RaceCell>
    <RaceCell>{race.date}</RaceCell>
    <RaceCell>{race.status}</RaceCell>
    <RaceCell>{race.time}</RaceCell>
    <RaceCell>{race.shoes}</RaceCell>
    <RaceCell>{race.notes}</RaceCell>
  </tr>
);

export const RaceTable: React.FC<{ readonly races: readonly Race[] }> = ({
  races,
}) => (
  <table className="w-full">
    <tbody>
      {races.map((race, i) => (
        <RaceRow key={`race-${i}`} race={race} />
      ))}
    </tbody>
  </table>
);
